@import "shared/styles/global";

.footer {
  background-color: var(--color-black);
  width: 100%;
  z-index: 1;
  @media (min-height: 1200px) {
    position: sticky;
    bottom: 0;
  }
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-top: rem(1) solid var(--color-border);
    pointer-events: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: {
    top: rem(20);
    bottom: rem(20);
  }
}

.text {
  color: var(--color-primary);
  font-size: rem(14);
  margin: 0;
  line-height: 1.286;
  a {
    color: var(--color-white);
    transition: text-decoration-color 0.25s ease;
    text-decoration-color: transparent;
    @media (hover: hover) {
      &:hover {
        text-decoration-color: currentColor;
      }
    }
  }
}
