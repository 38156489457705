@import 'shared/styles/global';

.button {
    @include resetButton;
    position: relative;
    width: rem(28);
    height: rem(25);
    // aspect-ratio: 1160/1024;
    flex-direction: column;
    display: none;
    flex-shrink: 0;
    @media (max-width: $tablet-v) {
        display: inline-flex;
    }
}

.icon {
    fill: var(--color-white);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.25s ease;
}

.visibleOnActive {
    transform: scale(0);
    .active & {
        transform: scale(1);
    }
}

.hiddenOnActive {
    .active & {
        transform: scale(0);
    }
}
