@import "shared/styles/global";

.accordion {
  display: flex;
  flex-direction: column-reverse;
  border-top: rem(2) solid var(--color-light);
  border-bottom: rem(2) solid var(--color-light);
  & + & {
    border-top: 0;
  }
}

.button {
  @include resetButton;
  width: 100%;
  display: flex;
  align-items: center;
  gap: rem(20);
  padding: rem(23) rem(13);
  justify-content: space-between;
  font-size: rem(18);
  font-weight: 700;
  color: var(--color-dark);
  transition: color 0.25s ease;

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary);
    }
  }
  @media (max-width: $phone-v) {
    font-size: rem(16);
    padding: {
      top: rem(20);
      bottom: rem(20);
    }
  }
}

.icon {
  width: rem(20);
  height: rem(20);
  fill: currentColor;
  transform: rotate(180deg);
  transition: transform 0.25s ease;
}

.content {
  border-top: rem(1) solid var(--color-dark);
  border-top-color: var(--color-file);
  
  &[style*="display: none;"] ~ .button .icon {
    transform: rotate(0deg);
  }
}
