@import "shared/styles/global";

.button {
  @include resetButton;
  width: rem(40);
  height: rem(40);
  position: sticky;
  bottom: rem(20);
  margin-left: auto;
  transform: translateX(calc(100% + -40px));
  margin-bottom: rem(20);
  transition: 0.25s ease;
  opacity: 0;
  z-index: 9999;
  @media (min-height: 1200px) {
    bottom: rem(250);
  }
  @media (max-width: 1340px) {
    transform: translateX(0);
  }
  @media (hover: hover) {
    &:hover {
      transform: translateX(calc(100% + -40px)) scale(1.1);
    }
  }
  &.active {
    opacity: 1;
  }
}

.icon {
  width: 100%;
  height: 100%;
}
