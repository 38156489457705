@import "shared/styles/global";

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(20);
  margin-bottom: rem(74);
  @media (max-width: $tablet-v) {
    margin-bottom: rem(50);
  }
  @media (max-width: $phone-v) {
    grid-template-columns: 1fr;
  }
}
.buttons {
  display: flex;
  flex-direction: column;
  gap: rem(15);
  align-items: flex-start;
  @media (max-width: $phone-v) {
    gap: rem(10);
  }
}

.button {
  @include resetButton;
  display: flex;
  color: var(--color-black);
  font-size: rem(20);
  @media (max-width: $tablet-v) {
    font-size: rem(18);
  }
  @media (max-width: $phone-h) {
    font-size: rem(16);
  }
  gap: 0.7em;
  text-align: left;
  @media (hover: hover) {
    &:hover .checkmark {
      border-color: var(--color-hover);
    }
  }
}

.checkmark {
  width: rem(20);
  height: rem(20);
  border: rem(2) solid var(--color-light-4);
  border-radius: rem(4);
  flex-shrink: 0;
  margin-top: rem(1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(1);
  transition: border-color 0.25s ease;
  pointer-events: none;
}

.icon {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.25s ease;
  fill: var(--color-light);
  .active & {
    opacity: 1;
  }
}

.images {
  display: grid;
  background-color: var(--color-file);
}

.image {
  max-width: 100%;
  width: 100%;
  height: auto;
  grid-column-start: 1;
  grid-row-start: 1;
  opacity: 0;
  &.active {
    opacity: 1;
  }
}
