@font-face {
  font-family: NotoSans;
  src: url(https://assets.cdnppb.net/lac/fonts/NotoSans-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: NotoSans;
  src: url(https://assets.cdnppb.net/lac/fonts/NotoSans-Medium.ttf) format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: NotoSans;
  src: url(https://assets.cdnppb.net/lac/fonts/NotoSans-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: NotoSans;
  src: url(https://assets.cdnppb.net/lac/fonts/NotoSans-ExtraBold.ttf) format("truetype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

body {
  font-family: NotoSans;
}
