@mixin fluid-size($properties, $min-vw, $max-vw, $min-size, $max-size) {
    @each $property in $properties {
        #{$property}: $min-size;
    }
    @media (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(#{$min-size} + #{strip-unit($max-size - $min-size)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
        }
    }
    @media (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-size;
        }
    }
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    @return $number;
}

@function rem($target) {
    @return ($target / 16) * 1rem;
}

@mixin resetButton {
    border: 0;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
}

@mixin fileTemplateColumns {
    display: grid;
    grid-template-columns: 1fr 70px 22px;
    align-items: center;
    gap: 10px;
}
