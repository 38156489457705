@import 'shared/styles/global';

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(60);
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    opacity: 0.3s ease;
    isolation: isolate;
    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1;
    & ~ * {
        z-index: 2;
    }
}

.close {
    &Button {
        position: absolute;
        @include resetButton;
        top: rem(18);
        right: rem(20);
        color: var(--color-white);
        transition: color 0.25s ease;
        @media (hover: hover) {
            &:hover {
                color: var(--color-primary);
            }
        }
    }
    &Icon {
        fill: currentColor;
        width: rem(28);
        height: rem(25);
    }
}
