@import "shared/styles/global";

.container {
  margin-bottom: rem(20);
  display: flex;
  flex-direction: column;
  gap: rem(20);
  @include fluid-size(margin-bottom, $phone-h, $tablet-h, 50px, 66px);
}

.grid {
  display: grid;
  gap: rem(64);
  grid-template-columns: repeat(3, 1fr);
  align-self: flex-start;

  @media (max-width: $phone-v) {
    gap: rem(8);
  }
}

.noMargin {
  margin: 0;
}
