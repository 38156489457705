@import "shared/styles/global";

.accordion {
  display: flex;
  flex-direction: column-reverse;

  &Item {
    display: flex;
    flex-direction: column;
  }
  &Title {
    @include resetButton;
    color: var(--color-black);
    font-size: rem(24);
    font-weight: 700;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5em;
    transition: all 0.25s ease;
    border-radius: rem(10);
    background: var(--color-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: rem(16) rem(36);
    font-size: rem(18);
    font-style: normal;
    font-weight: 600;
    justify-content: space-between;

    &.isToggled {
      background: var(--color-primary);
      border-radius: rem(10) rem(10) 0px 0px;
      box-shadow: none;

      & .icon {
        transform: rotate(180deg);
      }

      @media (hover: hover) {
        &:hover {
          color: var(--color-white);

          & .icon {
            fill: var(--color-white);
          }
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-primary);

        & .icon {
          fill: var(--color-primary);
        }
      }
    }

    @media (hover: hover) and (max-height: $laptopHeight) {
      font-size: rem(20);
    }
    @media (max-width: $tablet-v) {
      display: none;
    }
  }
}

.collapsibleElement {
  @media (min-width: ($tablet-v + 1px)) {
    &.isToggled {
      border-radius: 0 0 rem(10) rem(10);
      background: var(--color-white);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }

  @media (max-width: $tablet-v) {
    display: block !important;
  }
}

.list {
  display: flex;
  flex-direction: column;
  font-size: rem(20);
  gap: 0.5em;
  @media (max-width: $tablet-h) {
    font-size: rem(18);
  }
  @media (hover: hover) and (max-height: $laptopHeight) {
    font-size: rem(18);
  }
  @media (max-width: $tablet-v) {
    font-size: rem(16);
  }
  .accordion & {
    padding-inline: rem(34);
    margin-block: rem(34);
    @media (max-width: $desktop) {
      margin-block: rem(20);
      padding-inline: rem(20);
    }
    @media (max-width: $tablet-v) {
      margin-bottom: 0;
      margin-top: 1em;
      padding-left: 0;
      gap: 0;
    }
  }
}

.button {
  @include resetButton;
  font-size: 1em;
  color: currentColor;
  line-height: 1;
  text-align: left;
  @media (max-width: $tablet-v) {
    color: var(--color-white);
    width: 100%;
    padding: rem(8) rem(16);
    text-align: left;
  }
  &.active {
    font-weight: 700;
    @media (max-width: $tablet-v) {
      transform: translateX(0);
    }
  }
}

.icon {
  width: rem(12);
  aspect-ratio: 1/1;
  flex-shrink: 0;
  transition: fill 0.25s ease, transform 0.25s ease;
}
