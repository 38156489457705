@import-normalize;

@import "shared/styles/fonts";
@import "shared/styles/global";

:root {
  --color-primary: #ffb80c;
  --color-carbon: #1e1e1e;
  --color-dark: #18181a;
  --color-arrows-fill: #242425;
  --color-border: #3f3f3f;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-hover: #ff9600;
  --color-accent: #405ccc;
  --color-light: #b4b4b8;
  --color-light-2: #777777;
  --color-light-3: #cdcdd0;
  --color-light-4: #eeeeee;
  --color-file: #f5f5f5;
  --color-error: #cf2f31;
  --color-success: #358618;
  --color-correct: #00dd4b;
  --color-incorrect: #ff0606;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  min-width: 0;
}

body {
  overflow-y: scroll;
  margin: 0;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  color: var(--color-black);
}

html {
  height: -webkit-fill-available;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

button,
a {
  outline: none;
  color: inherit;
}

select {
  color: inherit;
}

body .__react_component_tooltip {
  color: var(--color-white);
  border-radius: rem(8);
  backdrop-filter: blur(rem(10));
  background: var(--color-border);
  font-size: rem(14);
  padding: rem(8) rem(10);
  &.show {
    opacity: 1;
  }
  &.place {
    &-top:after {
      border-top-color: var(--color-border);
    }
    &-bottom:after {
      border-bottom-color: var(--color-border);
    }
    &-left:after {
      border-left-color: var(--color-border);
    }
    &-right:after {
      border-right-color: var(--color-border);
    }
  }
}
