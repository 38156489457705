@import "shared/styles/global";

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include fluid-size(gap, $tablet-h, $desktop, 24px, 36px);
  @include fluid-size(margin-bottom, $tablet-v, $tablet-h, 50px, 90px);

  @media (max-width: $tablet-v) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: rem(24);
  margin-left: rem(-34);

  @media (max-width: $tablet-h) {
    margin-left: 0;
  }
}

.paddingRight {
  padding-right: rem(58);
  @media (max-width: $tablet-h) {
    padding-right: 0;
  }
}

.bottomContent {
  margin-top: rem(-40);
  margin-left: rem(34);

  @media (max-width: $tablet-h) {
    margin: 0;
  }
}

.aside {
  margin-top: rem(24);
  display: flex;
  flex-direction: column;
  gap: rem(36);

  @media (max-width: $tablet-h) {
    margin-top: 0;
    gap: rem(16);
  }
}

.bottomText {
  display: flex;
  flex-direction: column;
  gap: rem(12);
}
