@import "shared/styles/global";

.image {
  max-width: 100%;
  height: auto;
  @include fluid-size(margin-bottom, $phone-s, $tablet-v, 50px, 74px);
}

.smallMargin {
  margin-bottom: rem(20);
}

.mediumMargin {
  margin-bottom: rem(36);
}

.noMargin {
  margin-bottom: 0;
}

.whiteBg {
  background-color: var(--color-white);
  padding: 3%;
}

.contain {
  object-fit: contain;
}

.fullWidth {
  width: 100%;
}
