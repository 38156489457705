@import "shared/styles/global";

.nav {
  position: sticky;
  top: var(--top-section-height);
  padding-right: 20px;
  @include fluid-size(padding-right, $desktop, $desktop + 40, 20px, 40px);
  padding-block: rem(70);
  display: flex;
  flex-direction: column;
  gap: rem(30);
  max-height: 80vh;
  overflow: auto;
  @media (hover: hover) and (max-height: $laptopHeight) {
    padding: {
      top: rem(20);
      bottom: rem(20);
    }
    gap: rem(20);
  }
  @media (max-width: $tablet-v) {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: var(--color-dark);
    z-index: 99999;
    padding: 0;
    overflow: auto;
    transform: translateX(100%);
    transition: transform 0.25s ease;
    max-height: none;
    gap: 0;
    padding: {
      top: rem(10);
      bottom: rem(10);
    }
    &.active {
      transform: translateX(0);
    }
  }
}
