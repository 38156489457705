@import "shared/styles/global";

.container {
  position: relative;
  display: flex;
  height: 120%;
}
.soundwave {
  display: flex;
  align-items: center;
  gap: rem(6);
}

.bar {
  border-radius: rem(20);
  width: rem(7);
  flex-shrink: 0;
  background-color: var(--color-white);

  @for $i from 1 through 26 {
    &:nth-child(#{$i}) {
      animation-duration: (random(100) + 400) + ms !important;
    }
    .active & {
      animation: sound 0ms -800ms linear infinite alternate;
    }
  }
  @media (max-width: $tablet-h) {
    &:nth-child(n + 16) {
      display: none;
    }
  }
  @media (max-width: $tablet-v) {
    &:nth-child(n + 16) {
      display: block;
    }
    &:nth-child(n + 20) {
      display: none;
    }
  }
  @media (max-width: $phone-h) {
    &:nth-child(n + 16) {
      display: none;
    }
  }
  @media (max-width: $phone-v) {
    width: rem(6);
    &:nth-child(n + 10) {
      display: none;
    }
  }
}

@keyframes sound {
  0% {
    height: 25%;
  }
  100% {
    height: 100%;
  }
}
