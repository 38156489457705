@import 'shared/styles/global';

.overflow {
    width: 100%;
    overflow: auto;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
