@import "shared/styles/global";

.button {
  @include resetButton;
  font-size: rem(20);
  line-height: 1.3;
  text-align: left;
  display: inline-flex;
  gap: 0.5em;
  font-weight: 700;
  margin: 0 0 3.75em 2em;
  color: currentColor;
  text-decoration: none;
  @media (max-width: $tablet-v) {
    font-size: rem(17);
  }
  @media (max-width: $phone-v) {
    margin-left: 0;
    font-size: rem(15);
  }
  @media (hover: hover) {
    &:hover .icon {
      fill: var(--color-hover);
    }
  }
}

.noMargin {
  margin: 0;
  align-items: center;
}

.icon {
  width: 1.2em;
  height: 1.2em;
  fill: var(--color-primary);
  transition: fill 0.25s ease;
  flex-shrink: 0;
}
