@import "shared/styles/global";

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
}

.info {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  font-size: rem(11);
  line-height: 1.636;
  font-weight: 400;
  padding: rem(10) rem(30);
  gap: rem(4);

  @media (max-width: $tablet-v) {
    font-size: rem(10);
  }

  @media (max-width: $phone-s-larger) {
    line-height: 1;
    padding: rem(4) rem(12);
  }
}

.text {
  margin: 0;
}

.img {
  width: rem(177);
  height: 100%;
  object-fit: cover;
}
