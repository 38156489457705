@import "shared/styles/global";

.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(38);
  @include fluid-size(margin-bottom, $phone-s, $tablet-v, 40px, 74px);
  & > *:nth-child(3n) {
    grid-column: 1/-1;
  }

  &.bgColorBlack {
    gap: rem(16);
  }
  &.bgColorWhite{
    gap: rem(16);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  @include fluid-size(gap, $phone-s, $tablet-v, 20px, 40px);
}

.bigImagesOnly {
  & > *:nth-child(3n) {
    grid-column: auto/auto;
  }
}

.rowOf3 {
  grid-template-columns: repeat(3, 1fr);
}

.rowOf4 {
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: $phone-v) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.rowOf7 {
  grid-template-columns: repeat(7, 1fr);

  @media (max-width: $tablet-h) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: $phone-v) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.link {
  display: flex;
  position: relative;

  &.bgColorBlack {
    background-color: var(--color-black);
    width: 100%;
    height: rem(110);
  }
  &.bgColorWhite {
    background-color: var(--color-white);
    width: 100%;
    height: rem(110);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-black);
    opacity: 0;
    visibility: hidden;
    transition: 0.25s ease;
  }
  &:hover:before {
    opacity: 0.4;
    visibility: visible;
  }
}


.icon {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.25s ease;
  fill: var(--color-primary);
}

.link:hover .icon {
  opacity: 1;
  visibility: visible;
}

.imgWrapper {
  position: relative;
}

.dos {
  &:after {
    content: "";
    position: absolute;
    top: rem(-14);
    left: rem(-14);
    background-image: url(../../assets/correct.svg);
    width: rem(26);
    height: rem(26);
  }
}

.donts {
  &:after {
    content: "";
    position: absolute;
    top: rem(-14);
    left: rem(-14);
    background-image: url(../../assets/incorrect.svg);
    width: rem(26);
    height: rem(26);
  }
}
