@import "shared/styles/global";

.subheading {
  @include fluid-size(font-size, $phone-v, $tablet-v, 20px, 24px);
  @include fluid-size(font-size, $tablet-v + 1, $desktop, 20px, 24px);
  font-size: rem(20);
  font-weight: 700;
  color: currentColor;
  margin: 0;
  margin-bottom: 1em;
}

.marginL {
  margin-bottom: rem(28);
}

.marginXL {
  margin-bottom: rem(42);
}

.marginXXL {
  @include fluid-size(margin-bottom, $phone-s, $tablet-v, 50px, 74px);
}
