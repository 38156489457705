@import "shared/styles/global";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: var(--color-light);
  margin-bottom: rem(9);
  padding-inline: rem(13);
  width: 100%;
  @media (max-width: $tablet-h) {
    padding: {
      left: rem(13);
      right: rem(13);
    }
  }
  @media (max-width: $tablet-v) {
    padding: {
      left: rem(13);
      right: rem(30);
    }
  }
  @media (max-width: $phone-h) {
    padding: {
      left: rem(13);
      right: rem(13);
    }
  }
}

.title {
  font-size: rem(15);
  font-weight: 400;
  &:not(:first-child) {
    text-align: center;
  }
  @media (max-width: $phone-v) {
    font-size: rem(14);
  }
}
