@import "shared/styles/global";

.container {
  display: flex;
  gap: rem(15);
  @include fluid-size(margin-bottom, $phone-s, $tablet-v, 30px, 44px);

  @media (max-width: $phone-v) {
    flex-direction: column;
  }
}

.img {
  position: relative;
  background-color: var(--color-primary);
  z-index: 1;
  width: rem(177);
  height: rem(110);
  object-fit: contain;

  @media (max-width: $phone-v) {
    width: 100%;
  }
}
