@import "shared/styles/global";

.twoCol {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: $tablet-v) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

.threeCol {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: rem(16);
  align-self: flex-start;
  @include fluid-size(margin-bottom, $phone-h, $tablet-h, 30px, 44px);

  @media (max-width: $tablet-h) {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-self: auto;
  }
}

.fourCol {
  display: flex;
  gap: rem(16);
  @include fluid-size(margin-bottom, $phone-h, $tablet-h, 50px, 98px);

  @media (max-width: $tablet-h) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
}

.paddingBottom {
  @include fluid-size(margin-bottom, $phone-s, $tablet-v, 50px, 74px);
}

.flex {
  display: flex;
  gap: rem(28);

  @media (max-width: $phone-h) {
    flex-direction: column;
    gap: rem(16);
  }
}
