@import "shared/styles/global";

.container {
  display: flex;
  @include fluid-size(gap, $tablet-h, $desktop, 50px, 64px);

  @media (max-width: $phone-h) {
    flex-direction: column;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  gap: rem(48);
}
