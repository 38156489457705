@import "shared/styles/global";

.container {
  display: flex;
  gap: rem(15);
  margin-bottom: rem(58);
  @include fluid-size(margin-bottom, $phone-h, $tablet-h, 50px, 58px);

  @media (max-width: $phone-v) {
    flex-direction: column;
    gap: rem(30);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: rem(177);
  height: max-content;
  align-items: center;

  @media (max-width: $tablet-v) {
    width: 100%;
  }

  @media (max-width: $phone-v) {
  }
}

.img {
  width: 100%;
  height: rem(110);
  object-fit: contain;

  @media (max-width: $tablet-v) {
    height: 100%;
  }
}
