@import 'shared/styles/global';

.wrapper {
    width: 100%;
    max-width: $desktop;
    margin: {
        left: auto;
        right: auto;
    }
    padding: {
        left: rem(20);
        right: rem(20);
    }
    position: relative;
}
