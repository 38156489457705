@import "shared/styles/global";

:root {
  --top-section-height: 6.313rem;
}
@media (max-width: $tablet-v) {
  :root {
    --top-section-height: 5.938rem;
  }
}
@media (max-width: $phone-h) {
  :root {
    --top-section-height: 5.625rem;
  }
}
@media (max-width: $phone-v) {
  :root {
    --top-section-height: 4.6875rem;
  }
}
@media (hover: hover) and (max-height: $laptopHeight) {
  :root {
    --top-section-height: 5.625rem;
  }
}

.container {
  z-index: 9999;
  position: sticky;
  top: 0;
  background-color: var(--color-primary);
  overflow: hidden;
  @media (max-width: $tablet-h) {
    display: flex;
    align-items: center;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  height: var(--top-section-height);
  gap: rem(16);

  @media (max-width: 1260px) {
    padding-left: rem(40);
    margin: 0;
  }
  @media (max-width: $phone-v) {
    padding-left: rem(20);
  }
}

.logo {
  max-width: 138px;
  height: 24px;
  fill: var(--color-black);

  @media (max-width: $phone-s-larger) {
    display: none !important;
  }
}

.title {
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
  font-weight: 700;
  color: var(--color-black);
  line-height: 1.3125;

  @media (max-width: $phone-h) {
    @include fluid-size(font-size, $phone-v + 1, $phone-h, 28px, 32px);
  }

  @media (max-width: $phone-v) {
    @include fluid-size(font-size, $phone-s + 1, $phone-v, 24px, 28px);
  }

  @media (max-width: $phone-s-larger) {
    text-align: left;
  }
}

.description {
  color: var(--color-primary);
  font-weight: 400;
  margin: 0;
  font-size: rem(20);
  @media (max-width: $phone-h) {
    font-size: rem(16);
  }
  @media (max-width: $phone-v) {
    font-size: rem(14);
  }
}
