@import "shared/styles/global";

.container {
  display: flex;
  flex-direction: column;
  gap: rem(16);
}

.backgroundColor {
  width: rem(72);
  aspect-ratio: 1/1;
  border-radius: rem(10);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.content {
  display: flex;
  flex-direction: column;
}

.title,
.label {
  color: var(--color-black);
  margin: 0;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.454;
}
