@import "shared/styles/global";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--color-black);
  margin-bottom: rem(46);
  gap: 1em;
  font-size: rem(16);
  font-style: normal;

  &:last-child {
    margin-bottom: 0;
  }
  &.white {
    color: var(--color-white);
  }
  &.red {
    color: var(--color-incorrect);
  }

  p {
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
  }
  strong {
    font-weight: 700;
  }
}

.smallFont {
  @include fluid-size(font-size, $phone-h, $tablet-h, 14px, 16px);
}

.smallMargin {
  margin-bottom: 1em;
}

.mediumMargin {
  margin-bottom: rem(36);
}

.noMargin {
  margin: 0;
}
