@import "shared/styles/global";

:root {
  --header-height: 80px;
}

.header {
  background-color: var(--color-primary);
  width: 100%;
  position: relative;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--header-height);
}

.logo {
  width: rem(159);
  height: rem(29);
  fill: var(--color-black);
}
