@import "shared/styles/global";

.link {
  @include fileTemplateColumns;
  color: var(--color-dark);
  text-decoration: none;
  padding: rem(23) rem(30);
  transition: background-color 0.25s ease;
  border-bottom: rem(1) solid var(--color-light);
  width: 100%;
  @media (max-width: $tablet-h) {
    padding: {
      left: rem(13);
      right: rem(13);
    }
  }
  @media (max-width: $tablet-v) {
    padding: {
      left: rem(30);
      right: rem(30);
    }
  }
  @media (max-width: $phone-h) {
    padding: {
      left: rem(13);
      right: rem(13);
    }
  }
  @media (max-width: $phone-v) {
    padding: {
      top: rem(20);
      bottom: rem(20);
    }
  }
  &:last-child {
    border-bottom: 0;
  }
  @media (hover: hover) {
    &:hover {
      background-color: var(--color-file);
    }
  }
}

.icon {
  width: 100%;
  height: 100%;
  fill: var(--color-accent);
}

.name {
  font-size: rem(15);
  font-weight: 700;
  color: var(--color-dark);
  word-break: break-all;
  @media (max-width: $phone-v) {
    font-size: rem(14);
  }
}

:global() .link:hover .name {
  color: var(--color-dark);
}

.extension {
  font-size: rem(14);
  font-weight: 700;
  text-align: center;
  color: var(--color-light-2);
  text-transform: uppercase;
  @media (max-width: $phone-v) {
    font-size: rem(13);
  }
}
