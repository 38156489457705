$desktop: 1354px;
$desktop-small: 1120px;
$tablet-h: 1024px;
$tablet-v: 768px;
$phone-h: 640px;
$phone-v: 480px;
$phone-s-larger: 380px;
$phone-s: 320px;

$laptopHeight: 690px;
$laptopWidth: 1360px;
