@import "shared/styles/global";

.container {
  max-width: rem(177);
  position: relative;
  transition: opacity 0.25s ease;
  flex: 1;

  @media (hover: hover) {
    &:hover {
      & .img {
        opacity: 0.6;

        &.absolute {
          opacity: 0.1;
        }
      }

      & .text {
        opacity: 1;
      }
    }
  }
}

.img {
  transition: opacity 0.25s ease;
}

.text {
  opacity: 0;
  transition: opacity 0.25s ease;
  width: fit-content;

  &.absolute {
    position: absolute;
    padding: rem(8);
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: $tablet-h) {
      position: relative;
      top: 0;
      transform: translateY(0);
      padding: 0;
    }
  }

  @media (max-width: $tablet-h) {
    opacity: 1;
  }
}
