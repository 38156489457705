@import 'shared/styles/global';

.container {
    display: flex;
    justify-content: center;
    &.leftAlign {
        justify-content: flex-start;
    }
    &.marginBottom {
        margin-bottom: rem(20);
    }
}

.button {
    @include resetButton;
    border-radius: rem(4);
    background-color: var(--color-primary);
    color: var(--color-black);
    font-size: rem(26);
    padding: 0.653846154em 0.769230769em;
    font-weight: 700;
    min-width: rem(300);
    transition: background-color 0.25s ease;
    line-height: 1;
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.25em;
    cursor: default;
    &.price {
        color: var(--color-white);
        background-color: var(--color-accent);
    }
    &.secondary {
        background-color: var(--color-dark);
        color: var(--color-white);
    }
    @media (max-width: $phone-v) {
        font-size: rem(20);
    }
    @media (max-width: $phone-s) {
        min-width: 0;
        width: 100%;
    }
    @media (hover: hover) {
        &:not(.disableHover):hover {
            background-color: var(--color-hover);
            cursor: pointer;
        }
    }
}

.scratchedText {
    font-size: 60%;
    font-weight: 400;
    text-decoration: line-through;
}
