@import 'shared/styles/global';

.container {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    margin-bottom: rem(20);
    @include fluid-size(gap, $tablet-v, $desktop, 20px, 50px);
    @media (max-width: $phone-v) {
        gap: rem(10);
    }
}

.button {
    @include resetButton;
    @include fluid-size(font-size, $phone-s, $tablet-v, 14px, 20px);
    @include fluid-size(font-size, $tablet-v + 1, $desktop, 16px, 20px);
    font-size: rem(14);
    display: flex;
    align-items: center;
    text-align: left;
    gap: 1em;
    font-weight: 700;
    @media (max-width: $phone-v) {
        gap: rem(10);
    }
    @media (hover: hover) {
        &:hover .playIcon {
            fill: var(--color-hover);
        }
    }
}

.playIcon {
    width: 2.5em;
    height: 2.5em;
    fill: var(--color-primary);
    transition: fill 0.25s ease;
    flex-shrink: 0;
    @media (max-width: $phone-v) {
        width: 2em;
        height: 2em;
    }
}

.soundwaveIcon {
    width: 100%;
    height: 100%;
}
