@import "shared/styles/global";

.button {
  @include resetButton;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: rem(10);
}

.title {
  font-size: rem(15);
  color: var(--color-black);
  font-weight: 700;
  margin-bottom: rem(35);
}

.icon {
  width: rem(66);
  height: rem(80);
}
