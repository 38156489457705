@import "shared/styles/global";

:root {
  --main-layout-position: static;
}

.layout {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex-grow: 1;
  position: var(--main-layout-position);
  width: 100%;
  min-height: 100vh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: start;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;

  @media (max-width: $tablet-v) {
    grid-template-columns: 1fr;
  }
}

.section {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: rem(70) 0 rem(30) rem(70);
  width: 100%;
  min-width: 0;
  position: relative;
  @media (max-width: $tablet-v) {
    padding: rem(32) 0 rem(32) 0;
    border-left: 0;
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }
}
