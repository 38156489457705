@import "shared/styles/global";

:root {
  --intro-transform: 0%;
  --intro-transition-duration: 1s;
  --intro-position: static;
}

.intro {
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 1;
  transition: transform var(--intro-transition-duration) ease-out;
  transform: translateY(var(--intro-transform));
  position: var(--intro-position);
  @media (max-width: $tablet-v) {
    display: none;
  }
}

.container {
  background-color: var(--color-primary);
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: {
    top: rem(40);
    bottom: rem(40);
  }
  min-height: calc(100vh - var(--header-height));
  :global(.intro-arrows) {
    position: absolute;
    top: -80px;
    left: 0;
    width: 100%;
    height: calc(100% + var(--header-height));
  }

  :global(.intro-arrow-left-stroke) {
    opacity: 0;
  }
  :global(.intro-arrow-right-stroke) {
    opacity: 0;
  }
}

.wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: rem(24);
}

.content {
  margin: auto 0;
}

.title {
  @include fluid-size(font-size, $tablet-v, $desktop, 50px, 70px);
  font-weight: 800;
  color: var(--color-black);
  line-height: 1;
  margin: 0 0 0.375em;
}

.subheading {
  @include fluid-size(font-size, $tablet-v, $desktop, 30px, 40px);
  display: inline-block;
  color: var(--color-black);
  line-height: 1;
  position: relative;
  margin: 0 0 3em;

  &:before {
    content: "";
    background: url(single-Line.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: -44%;
    left: 0px;
    width: 100%;
    height: 36%;
    animation: reveal 0.5s ease;
  }
}

@keyframes reveal {
  0% {
    width: 0%;
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%, 0% 50%);
    object-fit: cover;
    width: 100%;
  }
}

.paragraph {
  color: var(--color-black);
}
