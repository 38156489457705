@import "shared/styles/global";

.heading {
  font-weight: 700;
  margin: 0;
  margin-bottom: rem(20);
  color: var(--color-black);
  @include fluid-size(font-size, $tablet-v + 1, $desktop, 28px, 32);
  font-style: normal;
  line-height: 2.25;
  display: flex;
  align-items: center;
  gap: rem(24);
}

.noMargin {
  margin: 0;
}

.smallLineHeight {
  line-height: 1;
}
